import styled from "styled-components";
import React, { useState } from "react";
import Layout from "../components/Layout";

const Text = styled.p`
  padding: 10px 10px 10px 10px;
  line-height: 150%;
`;

const ContactForm = styled.form``;

const Label = styled.label`
  line-height: 150%;
  margin-right: 10%;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-primary-border);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-primary-border);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-primary-border);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
`;

const Button = styled.input`
  background-color: var(--color-secondary);
  color:  var(--color-primary-text);
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default () => {
  const [gender, setGender] = useState("Frau");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const onGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGender(e.currentTarget.value);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.currentTarget.value);
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const send = async () => {
    await fetch("https://stifani.shop/api/v1/stifani-finanz/send-mail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        gender,
        name,
        email,
        subject,
        message
      })
    });
  };

  return (
    <Layout>
      <ContactForm>
        <Label>Anrede*</Label>
        <Select id="gender" value={gender} onChange={onGenderChange}>
          <option>Frau</option>
          <option>Herr</option>
        </Select><br />
        <Label>Name*</Label>
        <Input id="name" value={name} placeholder="Hans Müller" required onChange={onNameChange}></Input><br />
        <Label>Email*</Label>
        <Input id="email" value={email} type="email" placeholder="hans.mueller@mail.com" required onChange={onEmailChange}></Input><br />
        <Label>Betreff*</Label>
        <Input id="subject" value={subject} placeholder="Anfrage ..." required onChange={onSubjectChange}></Input><br />
        <Label>Nachricht*</Label>
        <TextArea id="message" value={message} rows={5} placeholder="Wir möchten gerne ..." required onChange={onMessageChange}></TextArea>
        <Button type="submit" value="Senden" onClick={send} />
      </ContactForm>

      <br /><br />

      <h3>Hauptstandort</h3>
      <Text>
        Stifani Finanz <br />
        Lättichstrasse 1 <br />
        6340 Baar / Zug <br />
        Schweiz / Switzerland CH <br />
      </Text>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1355.4934132900114!2d8.538007782518072!3d47.19727037714952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aabcd2f8eda97%3A0xb17de5e4ec5cfaca!2sL%C3%A4ttichstrasse%201%2C%206340%20Baar!5e0!3m2!1sde!2sch!4v1605885236592!5m2!1sde!2sch"
        style={{ width: "60vw", height: "40vh", border: "none" }}>
      </iframe>
    </Layout >
  )
};
